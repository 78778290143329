import React from 'react';
import {ColorGame} from './ColorGame';
import './App.css';

function App() {
  return (
    <div className="App">
      <ColorGame difficulty="9" />
    </div>
  );
}

export default App;
